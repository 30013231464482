import React from 'react'
import ContactNav from '../components/ContactNav';
import Contact from '../components/Contact';
import { Helmet } from 'react-helmet-async';

function ContactPage () {
  return (
    <div>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact us today to get started!"/>
        
      </Helmet>
      
        <ContactNav />
        <Contact />

    </div>
  )
}

export default Contact