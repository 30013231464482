import React, {useEffect} from 'react'
import './Services.css'

import KittyPaws from '../assets/KittyPaws.png'
import KittyPawsWeb from '../assets/KittyPawsWeb.webp'
import SmallPaws from '../assets/SmallPaws.png'
import SmallPawWeb from '../assets/SmallPawsWeb.webp'
import PuppyPaws from '../assets/PuppyPaws.png'
import PuppyPawsWeb from '../assets/PuppyPawsWeb.webp'
import DogWalking from '../assets/DogWalking.png'
import DogWalkingWeb from '../assets/DogWalkingWeb.webp'
import FooterImg from '../assets/FooterImg.png'
import FooterImgWeb from '../assets/FooterImgWeb.webp'

import Aos from 'aos';
import "aos/dist/aos.css";


const Services = () => {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, [])

  return (
    <div className='services' itemScope itemType='https://schema.org/Product'>
        <div className='services-container'>
            
            <div className='service-h2-container'>
                <h2>Services</h2>
            </div>

            <div className='service-boxes-container'>
                <div className='upper-row'>
                    <div data-aos="zoom-in"  className='service-box'>
                        <h3 itemProp='name'>CAT SITTING</h3>
                        <picture>
                            <source type="image/webp" srcSet={KittyPawsWeb} alt="Cartoon of a grey and brown cat." width="150px" height="150px" loading="lazy" title="Kitty Paws" />
                            <source type="image/png" srcSet={KittyPaws} alt="Cartoon of a grey and brown cat." width="150px" height="150px" loading="lazy" title="Kitty Paws" />
                            <img src={KittyPawsWeb} alt="Cartoon of a grey and brown cat." width="150px" height="150px" loading="lazy" title="Kitty Paws" />
                        </picture>
                        
                        <p>Your cat will have all their needs met!</p>
                        <br />
                        <p>Visits include:</p>
                        <p itemProp='description'>feeding, cleaning the litter box, playtime, 
                            brushing, love and attention.
                        </p>
                        <br />
                        <p itemProp='price'><b>Single Visit: $25</b><span><i > * plus tax *</i></span></p>
                        <p itemProp='price'><i>*For Each Additional Pet: Add $5*</i></p>
                        <button>
                            <a href="https://thepawcompany.ca/contact">
                                Get Started
                            </a>
                        </button>

                    </div>
                    <div data-aos="zoom-in" className='service-box dark'>
                        <h3 itemProp='name'>SMALL PET SITTING</h3>
                        <picture>
                        <source type="image/webp" srcSet={SmallPawWeb} alt="Catroon of a brown and white hamster." width="150px" height="150px" loading="lazy" title="Small Paws"  />
                        <source type="image/png" srcSet={SmallPaws} alt="Catroon of a brown and white hamster." width="150px" height="150px" loading="lazy" title="Small Paws"  />
                        <img src={SmallPawWeb} alt="Catroon of a brown and white hamster." width="150px" height="150px" loading="lazy" title="Small Paws" />
                        </picture>
                        
                        <p>Your little paw friend will be happy to!</p>
                        <br />
                        <p>Visits include:</p>
                        <p>Feeding, watering, love and attention 
                            (If they require it).
                        </p>
                        <br />
                        <p itemProp='price'><b>Single Visit: $20</b><span><i > * plus tax *</i></span></p>
                        <p itemProp='price'><i>*For Each Additional Pet: Add $5*</i></p>
                        <br />
                        <br />
                        <button>
                            <a href="https://thepawcompany.ca/contact">
                                Get Started
                            </a>
                        </button>
                        
                    </div>
                </div>
                <div className='lower-row'>
                    <div data-aos="zoom-in" className='service-box'>
                        <h3 itemProp='name'>DOG SITTING</h3>
                        <picture>
                        <source type="image/webp" srcSet={PuppyPawsWeb} alt="Cartoon of a brown and white puppy with a leash in his mouth." width="150px" height="150px" loading="lazy" title="Dog Sitting" />
                        <source type="image/png" srcSet={PuppyPaws} alt="Cartoon of a brown and white puppy with a leash in his mouth." width="150px" height="150px" loading="lazy" title="Dog Sitting" />
                        <img src={PuppyPawsWeb} alt="Cartoon of a brown and white puppy with a leash in his mouth." width="150px" height="150px" loading="lazy" title="Dog Sitting"/>
                        </picture>
                        
                        <p>Your dog will get lots of care while you're away!</p>
                        <br />
                        <p>Daily drop-ins: </p>
                        <p itemProp='price'><b>Single Visit: $22</b><span><i > * plus tax *</i></span></p>
                        <br />
                        <p>In-Home Overnight Care: </p>
                        <p itemProp='price'><b>Daily Rate: $65</b><span><i > * plus tax *</i></span></p>
                        <br />
                        <p itemProp='price'><i>*For Each Additional Pet: Add $5*</i></p>
                        <br />
                        <button>
                            <a href="https://thepawcompany.ca/contact">
                                Get Started
                            </a>
                        </button>
                    </div>
                    <div data-aos="zoom-in" className='service-box'>
                        <h3 itemProp='name'>DOG WALKING</h3>
                        <picture>
                            <source type="image/webp" srcSet={DogWalkingWeb} alt="Cartoon of a brown dog leash." width="150px" height="150px" loading="lazy" title="Dog Walking" />
                            <source type="image/png" srcSet={DogWalking} alt="Cartoon of a brown dog leash." width="150px" height="150px" loading="lazy" title="Dog Walking" />
                            <img src={DogWalkingWeb} alt="Cartoon of a brown dog leash." width="150px" height="150px" loading="lazy" title="Dog Walking" />
                        </picture>
                        
                        <p>Keep them active and healthy!</p>
                        <br />
                        <p>30 minute on leash walks:</p>
                        <p itemProp='price'><b>Single walk: $22</b><span><i > * plus tax *</i></span></p>
                        <br />
                        <p>1 hour off leash walk:</p>
                        <p itemProp='price'><b>Single walk: $35</b><span><i > * plus tax *</i></span></p>
                        <br />
                        <p itemProp='price'><i>*For Each Additional Dog: Add $5*</i></p>
                        <br />
                        <button>
                            <a href="https://thepawcompany.ca/contact">
                                Get Started
                            </a>
                        </button>
                    </div>
                </div>

            </div>

             <div className='services-footer-container'>
                <picture>
                    <source type="image/webp" srcSet={FooterImgWeb} />
                    <source type="image/png" srcSet={FooterImg} />
                    <img src={FooterImgWeb} alt="White outline of dog head with heart above it." width="150px" height="150px" loading="lazy" title="Dog Outline with Heart"/>
                </picture>
                 
                <div className='services-footer'>
                    <h2>Not sure what you need?</h2>
                    <p>We'll answer any questions you may have, help with scheduling, or anything else you may need.</p>
                    <div className='services-button-container'>
                        <button>
                            <a href="https://thepawcompany.ca/contact">
                                Email Us
                            </a>
                        </button>
                        <button>
                            <a href="tel:807 252 8124">
                                Call Us
                            </a>
                        </button>
                    </div>
                </div>
             </div>

        </div>
    </div>
  )
}

export default Services