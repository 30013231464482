import{ BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';
import FAQ from './pages/FAQPage';
import PageNotFound from './pages/PageNotFound';

function App() {
  return (
    <div>
      <BrowserRouter>      
        <Routes>
            <Route path = '/' element={<HomePage />} />
            <Route path = '/contact' element={<ContactPage />} />
            <Route path = '/faq' element={<FAQ />} />
            <Route path= '*' element={<PageNotFound />} />
            <Route path='/error' element={<PageNotFound />} />
        </Routes>
       </BrowserRouter>
     </div>
  );
} 
 

export default App;
