import React from 'react'
import Error404 from '../components/Error404'

const PageNotFound = () => {
  return (
    <div>
        <Error404 />
    </div>
  )
}

export default PageNotFound