import React from 'react'
import './Footer.css'
import LogoAnimated from '../assets/LogoAnimated.mp4'
import { MdFacebook } from 'react-icons/md'
import {RiInstagramFill} from 'react-icons/ri'
import { MdCopyright } from 'react-icons/md'

const footer = () => {
  return (
    <div className='footer'>
        <div className="footer-container">
            <div className="company-name">
                <video  width="100" height="100" autoPlay="autoplay" muted loop>
                    <source src={LogoAnimated} type="video/mp4" />
                </video>
                    <h2>The Paw Company</h2>
                </div>
            <div className="social-media">   
                <h2>Follow us!</h2>
                <div className="socials">
                    <div className="facebook">
                        <MdFacebook />
                        <a href="https://www.facebook.com/The-Paw-Company-100555469383028/?ref=page_internal" target="_blank" rel="noreferrer" ><h3>Facebook</h3></a>
                    </div>
                    <div className="instagram">
                        <RiInstagramFill />
                        <a href="https://www.instagram.com/itsthepawcompany/" target="_blank" rel="noreferrer" ><h3>Instagram</h3></a>
                    </div>
                </div>
            </div>
            <div className="operation">
                <h2>Hours of Operation</h2>
                <div className="operation-hours">
                    <div className="walking-hours">
                        <h3>Dog Walking:</h3>
                        <p>Mon-Fri: 9am - 6pm</p>
                        <p className='extra-info'>*Please inquire for weekend availability*</p>
                    </div>
                    <div className="sitting-hours">
                        <h3>Dog Sitting:</h3>
                        <p>24/7</p>
                        <p className="extra-info">*Overnight Boarding is currently not available*</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom-footer">
        <div className="copyright">
            <MdCopyright />
            <p> 2022-2024 The Paw Company. All Rights Reserved.</p>
            <p>Site Here</p>
        </div>
        <p>Grown from Seed to Site, Sustained Growth by <a href="https://www.techgrowthsolutions.com" target="_blank" rel="noopener noreferrer">Tech Growth Solutions</a></p>
        </div>

    </div>
  )
}

export default footer