import React from 'react'
import { Helmet } from 'react-helmet-async';

import ContactNav from '../components/ContactNav';
import FAQ from '../components/FAQ/faq';

const FAQPage = () => {
  return (
    <div>
        <Helmet>
            <title>Frequently Asked Questions</title>
            <meta name="description" content="Have more questions? We have answers! Take a look at our most frequent questions!"/>
        </Helmet>
        <ContactNav />
        <FAQ />
    </div>
  )
}

export default FAQPage