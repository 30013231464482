import React from 'react'
import Home from '../components/Home';
import About from '../components/About';
import Services from '../components/Services';
import Navbar from '../components/Navbar';
import Reviews from '../components/Reviews';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';

function HomePage () {
  return (
    <div>
      <Helmet>
        <title>The Paw Company-Reliable Dog Walking/Pet Sitting Service.</title>
        <meta name="description" content="Treat your pet right with Thunder Bay's most reliable pet sitting and dog walking services company. Group or solo walks are available. Regular scheduled or last minute bookings are all welcome. Get started today!"/>     
      </Helmet>
        <Navbar />
        <Home />
        <About />
        <Services />
        <Reviews />
        <Footer />
    </div>
  );
}

export default HomePage