import React from 'react'
import './Error404.css'

import errorbackgroundWeb from '../assets/errorbackgroundWeb.webp'
import errorbackground from '../assets/errorbackground.png'

const PageNotFound = () => {
  return (
    <div className='Error-Page'>
        <div className='error-container'>
          <div className="error-message">
            <h1>404 Page Not Found</h1>
            <h2>It looks like the page you are looking for is out on a walk!</h2>
            <h3>Don't worry, you can go back to the homepage and book a walk for your pet to!</h3>
            <button><a href="https://thepawcompany.ca/">Home</a></button>
          </div>
          <div className="error-image">
            <picture>
            <source type="image/webp" srcSet={errorbackgroundWeb} />
            <source type="image/png" srcSet={errorbackground} />
            <img src={errorbackground} alt="Dog on leash out for walk escaping from dog walker" width="300" height="300" loading="lazy" title="Error Background" />
            </picture>
          </div>
        </div>
    </div>
  )
}

export default PageNotFound