import React,{useState}  from "react";
import "./Contact.css";
import LogoAnimated from '../assets/LogoAnimated.mp4';
import {HiOutlineMenuAlt4} from 'react-icons/hi';
import {FaRegTimesCircle} from 'react-icons/fa';
import { Link } from 'react-scroll';

const Contact = () => {

  const[click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  return (
    <div className="contact-page">
      <div className='contact-navbar'>
       <div className='contact-nav-container'>
           <h1>THE PAW COMPANY</h1>
           <video  width="80" height="80" autoPlay="autoplay" muted loop>
              <source src={LogoAnimated} type="video/mp4" />
            </video>
           <ul className={click? 'nav-menu active' : 'nav-menu'}>
            <li>
              <a href="/">Home</a>
            </li>
           </ul>
           <div  className='hamburger' onClick={handleClick}>
             {click ? (<FaRegTimesCircle className='icon' />) : <HiOutlineMenuAlt4 className='icon' />}
             
           </div>
       </div>
    </div>
      <div className="contact">
        <form
          method="POST"
          action="https://formspree.io/f/mzbngldr"
        >

          <h2>Contact Us</h2>
          <label htmlFor="name">Full Name*</label>
          <input
            name="name"
            type="text"
            id="name"
            placeholder="Enter your name"
            required
          />
          <label htmlFor="email">Email Address*</label>
          <input
            name="email"
            type="text"
            id="email"
            placeholder="Enter your email"
            required
          />
          <label htmlFor="address">Home Address*</label>
          <input
            name="address"
            type="text"
            id="address"
            placeholder="Enter your home address"
            required
          />
          <label htmlFor="service">Select a service*</label>
          <select name="service" placeholder="select a service" required>
            <option value="">--Please Select a Service--</option>
            <option value="Cat Sitting">Cat Sitting</option>
            <option value="Small Pet Sitting">Small Pet Sitting</option>
            <option value="Dog Sitting">Dog Sitting</option>
            <option value="Dog Walking">Dog Walking</option>
          </select>
          <div className="sitting-type">
            <h3>Type of Sitting Required </h3>
            <div className="sitting-option">
            <div className="over-night">
               <label htmlFor="over-night">None</label>
               <input type="radio" name="Sitting-Type" id="none" value="none"/> 
              </div>
              <div className="over-night">
               <label htmlFor="over-night">Over Night Stay In Your Home</label>
               <input type="radio" name="Sitting-Type" id="over-night" value="Over Night"/> 
              </div>
              <div className="over-night">
                <label htmlFor="daily-drop-in">Daily Drop In</label>
                <input type="radio" name="Sitting-Type" id="daily-drop-in" value="Daily Drop In"/>
              </div>
              
            </div>
              
            
          </div>
          <div className="date-selector">
            <h3>Pet Sitting Dates (Optional) </h3>
            <br />
            <label htmlFor="start">Start Date</label>
            <input type="date" name="start-date" />
            
            <br />
            
            <label htmlFor="end">End Date</label>
            <input type="date" name="end-date" />
            </div>
          

          <label htmlFor="message">Message</label>
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Enter your message"
          ></textarea>
          <button type="submit">Submit</button>
        </form>
        <div className="company-info">
          <div className="location">
            <h2>LOCATION:</h2>
            <h3>Thunder Bay District</h3>
            <p><i><b>Note: </b>Pricing may vary for services in outlying areas of Thunder Bay. Please contact us for an exact quote.</i></p>
          </div>
          <div className="email">
            <h2>EMAIL ADDRESS:</h2>
            <h3>amityr@thepawcompany.ca</h3>
            <p><i><b>Note: </b> If you do not get a response within 24 hours, please check your 
              spam folder</i>
            </p>
          </div>
          <div className="number">
            <h2>PHONE NUMBER:</h2>
            <p>(807) 252-8124</p>
          </div>
          <button>Call Now</button>
        </div>
      </div>
    </div>
  );
};

export default Contact;
