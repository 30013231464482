import React, { useState } from 'react';

import './faq.css'
import './faqmobile.css'

const FAQ = () => {

const [selected, setSelected] = useState(null)

const toggle = (i) => {
    if (selected == i) {
        return setSelected(null);
    }

    setSelected(i)
}

  return (
    <div className='faq'>
      <div className="faq-container">
        <h1>Some Of Our Most Frequently Asked Questions!</h1>
        {data.map((item, i) => (
            <div className="item">
                <div className="questions" onClick={() => toggle(i)}>
                    <h2>{item.question}</h2>
                    <span>{selected === i ? '-' : '+'}</span>
                </div>
                <div className={selected === i ? 'answers show' : 'answers'}>
                    {item.answer}
                </div>
            </div>
        ))}
      </div>
    </div>
  )
}

const data = [
    {
        question: 'What is an off leash group walk?',
        answer: 'This service is for dogs who are well trained in off-leash dog parks. Your dog will get picked up in your desired time slot and will be matched with another dog that best suits his/her personality. At this time we currently have a max of 2 dogs per trip. The dogs will go for 1 hour from time of pickup. After the hour is up your dog will be dropped off at home and made sure their water is topped up before leaving.'
    },
    {
        question: 'What is a group neighborhood walk?',
        answer: 'This service is for dogs that are well trained on leash and are friendly with other dogs. Your dog will be picked up at your home and matched with another dog in your area. The duration of the walk is 30 minutes. After the walk, your dog will be returned home and their water will be topped up before leaving.'
    },
    {
        question: 'Do you offer walks in the evening    ?',
        answer: 'Evening walks can be arranged upon special request. The price for a late night walk will be a bit higher.'
    },
    {
        question: 'My dog is shy, is there a private walk option available?',
        answer: 'Private walks are available upon request. Please note that private walks are priced at a higher rate than standard walks.'
    },
    {
        question: 'How can I book you for sitting services?',
        answer: 'If you wish to book for a pet sitting service you can visit the contact page and fill in the type of service along with the dates you need. There you can choose between and overnight stay with your pet in your home, or a daily drop in to feed and care for your pet. Please note: to confirm your booking dates, a deposit will be required.'
    },
    {
        question: 'Are you available on holidays?',
        answer: 'Our dog walking service is not available on holidays. We do, however, offer our pet sitting services during the holidays. Please note: There is a higher rate on all holidays as well as very limited spots available.'
    },
]

export default FAQ
